<template>
	<Alert :message="error" v-if="error" />

	<form class="rm-form" @submit.prevent="updateCompany">
		<div class="p-1rem">
			<div id="logo-preview" @click="triggerFileInput">
				<img v-if="preview" :src="preview" />
				<p v-else>Click to add logo</p>
			</div>
			<div class="validation-text" v-if="fileError">
				{{ fileError }}
			</div>
			<!-- The hidden input -->
			<input
				type="file"
				ref="fileInput"
				style="display: none"
				@change="onFileChange"
			/>
			<div class="spacer-20"></div>
			<!-- <div class="form-input">
				<label>Company Logo:</label>
				
				
			</div> -->
			<div class="form-input">
				<label for="companyName">Company Name:</label>
				<input type="text" v-model="companyName" />
			</div>

			<div class="form-input">
				<label for="companyDescription">About:</label>
				<textarea
					class="t-area"
					v-model="companyDescription"
					rows="4"
				></textarea>
			</div>
			<div class="form-input">
				<label for="website">Website:</label>
				<input
					type="text"
					placeholder="e.g. riskmemo.com"
					v-model="websiteUrl"
				/>
			</div>
			<div class="form-input">
				<label for="email">Contact email:</label>
				<input type="text" v-model="email" />
			</div>

			<div class="form-messages" v-if="success">
				<p>Company updated</p>
			</div>

			<div class="form-submit">
				<page-loading v-if="isLoading" />
				<button class="btn btn-primary" v-else>Update</button>
			</div>
		</div>
	</form>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { projectFirestore } from "../../firebase/config";
import getDocumentOnce from "../../composables/getDocumentOnce";
import useStorage from "../../composables/useStorage";
import Alert from "../../components/Alert.vue";
import PageLoading from "../PageLoading.vue";
import useDocument from "../../composables/useDocument";

export default {
	name: "UpdateCompanyForm",
	components: { Alert, PageLoading },
	props: ["companyId"],
	setup(props) {
		const companyId = props.companyId;

		// Form refs
		const types = ["image/png", "image/jpeg"];
		const file = ref(null);
		const fileInput = ref(null);
		const preview = ref(null);
		const fileError = ref(null);
		const isLoading = ref(false);
		const success = ref(false);
		const companyName = ref("");
		const companyDescription = ref("");
		const websiteUrl = ref("");
		const email = ref("");

		const downloadUrl = ref(null);
		const error = ref(null);

		//   Company Data
		const db = projectFirestore;
		const companyRef = db.collection("companies").doc(companyId);

		onMounted(async () => {
			// Get once to pre-fill form
			const { document } = await getDocumentOnce(companyRef);

			if (document.value) {
				companyName.value = document.value.companyName || "";
				preview.value = document.value.companyLogo || "";
				companyDescription.value =
					document.value.companyDescription || "";
				websiteUrl.value = document.value.websiteUrl || "";
				email.value = document.value.email || "";
			}
		});

		const triggerFileInput = () => {
			fileInput.value.click();
		};

		const onFileChange = (e) => {
			const selected = e.target.files[0];

			if (
				selected &&
				types.includes(selected.type) &&
				selected.size < 1000000
			) {
				file.value = selected;
				preview.value = URL.createObjectURL(selected);
				fileError.value = null;
			} else {
				file.value = null;
				fileError.value = "Image must be png/jpg and less than 1mb";
			}
		};

		const updateCompany = async () => {
			isLoading.value = true;

			if (file.value) {
				const {
					error: storageErr,
					uploadCompanyImages,
					downloadUrl: url,
				} = useStorage();

				await uploadCompanyImages(file.value, companyId);

				if (storageErr.value) {
					error.value = "Could not upload logo";
					isLoading.value = false;
				}

				downloadUrl.value = url.value;
			}

			let sanitisedUrl = websiteUrl.value.replace(/^(https?:\/\/)/, "");

			let update = {
				companyName: companyName.value,
				companyDescription: companyDescription.value,
				websiteUrl: sanitisedUrl,
				email: email.value,
			};

			if (downloadUrl.value) {
				update.companyLogo = downloadUrl.value;
			}

			const { error: updateErr, updateDoc } = useDocument(companyRef);
			await updateDoc(update);

			if (updateErr.value) {
				error.value = "Could not update company";
				isLoading.value = false;
			} else {
				success.value = true;

				setTimeout(() => {
					isLoading.value = false;
					success.value = false;
				}, 1000);
			}
		};

		return {
			types,
			file,
			fileInput,
			triggerFileInput,
			preview,
			fileError,
			isLoading,
			success,
			companyName,
			downloadUrl,
			updateCompany,
			onFileChange,
			Alert,
			error,
			companyId,
			companyDescription,
			websiteUrl,
			email,
		};
	},
};
</script>

<style lang="scss" scoped>
#logo-preview {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	min-height: 110px;
	padding: 1rem;
	border-radius: $border-radius;
	background-color: $rm-off-white;
	// border: 1px dashed $rm-grey;
	&:hover {
		cursor: pointer;
		border: solid 2px $rm-pink;
	}
	img {
		max-height: 150px;
		max-width: 60%;
	}
}
.rm-form {
	.form-input {
		input {
			&::placeholder {
				color: $rm-grey !important;
			}
		}
		.t-area {
			border: none;
			background-color: $rm-off-white;
			width: 100%;
			font-family: "Poppins", sans-serif;
		}
	}
}
</style>
